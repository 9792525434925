export default class MainNav {

  constructor() {
	
	$("nav .toggle").click ((e)=>{

		if ($("nav").hasClass("active"))
		{
			this.closeDropDown();
		}
		else
		{
			this.openDropDown();
		}

		e.preventDefault();
	});
	
	/*
	$("nav .menu a").click((e)=>{
		console.log("nav click "+$(e.currentTarget).attr("href"));
		e.preventDefault();
	});
	*/
	
	if(window.location.hash) {
		//this.scrollTo(window.location.hash);
	}

  }


	


	openDropDown(item) {
		$("nav").addClass("active");
	}

	closeDropDown(item) {
		$("nav").removeClass("active");
	}
}
