import TopNav from './controllers/frame/TopNav';

var touchswipe = require('jquery-touchswipe');
var cookies = require('js-cookie');

export default class App {

	constructor() {
		this.loadedW = false;
		
		this.gapIndex = 0;
		this.swipeThreshold = 125;
		
		this.LAYERS_DICT = {};
		this.STATES = {};
		this.GROUPS = {};
		
		
		this.LAYERS_DICT_2 = {};
		this.STATES_2 = {};
		this.GROUPS_2 = {};
		this.ANIMATIONS_2 = [];
		this.PC_COUNTER = {
			pc : 0,
		};
		
		this.timeout = 3;
		this.carouselActive = true;
		this.countdown = this.timeout;
		this.workingFont = 0;
		
		
		var isSafari = !!navigator.userAgent.match(/Version\/[\d\.]+.*Safari/);
		var iOS = /iPad|iPhone|iPod/.test(navigator.userAgent) && !window.MSStream;
	
		if (isSafari && iOS) {
			$("body").addClass("ios");
		} 
		
	
		$('document').ready( e => this.handleAppReady(e) );
		
		this.mode = null;
		
		this.mobileCopy = $(".copy-mobile").html();
	}

	handleAppReady() {
		this.topnav = new TopNav;
		
		$(window).resize(()=>{this.handleResize();});
		$(window).scroll(()=>{this.handleResize();});
		this.handleResize();
		
		
		$(".menu a").click((e)=>{
			//console.log("click "+$(e.currentTarget).attr("href"));
			if ($(e.currentTarget).hasClass("menu-item"))
			{
				switch($(e.currentTarget).attr("href"))
				{
					case "#gap":
						this.showPage("gap");
						break;
					case "#stats":
						this.showPage("stats");
						break;
					case "#about":
						this.showPage("about");
						break;
					
				}
			}
			this.topnav.closeDropDown();
		});
		
		$('a.twitter').click(function(e) {
			e.preventDefault();
			var href = $(this).attr('href');
			window.open(href, "Twitter", "height=285,width=550,resizable=1");
			
			gtag('event', 'share', {
			  'event_category': 'twitter'
			});
		});
		
		$('.facebook').click(function(e) {
			e.preventDefault();
			var href = $(this).attr('href');
			window.open(href, "Facebook", "height=269,width=550,resizable=1");
			
			gtag('event', 'share', {
			  'event_category': 'facebook'
			});
		});
		
		$('.pinterest').click(function(e) {
			e.preventDefault();
			var href = $(this).attr('href');
			window.open(href, "Pinterest", "height=288,width=750,resizable=1");
			
			gtag('event', 'share', {
			  'event_category': 'pinterest'
			});
		});
		
		$('a.download').click(function(e) {
			
			gtag('event', 'download', {
			  'event_category': 'font',
			  'event_label': $(e.currentTarget).attr("href")
			});
		});
		
		
		// cookies modal
		
		$('a.cookies-close, a.cookies-accept').click(function(e) {
			
			
			$(".cookies").hide();
			$(".overlay").hide();
			
			cookies.set('acceptCookies', 'true', { expires: 365 });
			
			gtag('event', 'accept', {
			  'event_category': 'cookies-dialog'
			});
			
			e.preventDefault();
		});
		
		if (cookies.get('acceptCookies') == 'true')
		{
			$(".cookies").hide();
			$(".overlay").hide();
		}
		
		
		$(".scroll-to-view").click((e)=>{
			this.nextGap();
			e.preventDefault();
		});
		
		$(".pip").click((e)=>{
			let index = 0;
			for (let i=0; i < $(".pip").length; i++)
			{
				if ($(".pip")[i] == e.currentTarget)
				{
					index = i;
					break;
				}
			}
			
			this.setGapScroll(index);
			e.preventDefault();
		});
		
		$("section.about a[href='#stats']").click((e)=>{
			this.showPage("stats");
		});
		
		
		
		this.loadJSON('/public/data/animation_data.json', (json_obj)=>{this.init_animation(json_obj);});
		this.loadJSON('./public/data/animation_data2.json', (json_obj)=>{this.init_animation2(json_obj);});
		
		
		this.handleResize();
		
		if ($("section.gap").length)
		{
			
			switch(window.location.hash)
			{
				case "#about":
					this.showPage("about");
					break;
				case "#stats":
					this.showPage("stats");
					break;
				case "#gap":
					this.showPage("stats");
					break;
				default:
					this.showPage("stats");
					break;
			
			}
		
			
			$(window).scrollTop(0);
			setTimeout(()=>{$(window).scrollTop(0);}, 500);
			
		}
		else
		{
			$("body").addClass("text");
		}
	}
	
	showPage (target) {
		//console.log("show page "+target);
		$(window).scrollTop(0);
		
		switch(target)
		{
			case "gap":
				$("section.gap").addClass("active");
				$("section.stats").removeClass("active");
				$("section.about").removeClass("active");
				$("body").addClass("gap");
				$("body").removeClass("stats");
				$("body").removeClass("about");
				gtag('event', 'page_view', {
				  'page_title' : 'Gap',
				  'page_path': '/gap'
				});
				
				$("body").addClass("pink");
				$("body").removeClass("orange");
				$("body").removeClass("cherry");
				
				
				break;
			case "stats":
				$("section.gap").removeClass("active");
				$("section.stats").addClass("active");
				$("section.about").removeClass("active");
				$("body").removeClass("gap");
				$("body").addClass("stats");
				$("body").removeClass("about");
				gtag('event', 'page_view', {
				  'page_title' : 'Stats',
				  'page_path': '/stats'
				});
				
				$("body").removeClass("pink");
				$("body").removeClass("orange");
				$("body").removeClass("cherry");
				
				break;
			case "about":
				$("section.gap").removeClass("active");
				$("section.stats").removeClass("active");
				$("section.about").addClass("active");
				$("body").removeClass("gap");
				$("body").removeClass("stats");
				$("body").addClass("about");
				
				$("body").removeClass("pink");
				$("body").removeClass("orange");
				$("body").removeClass("cherry");
				
				
				gtag('event', 'page_view', {
				  'page_title' : 'About',
				  'page_path': '/about'
				});
				break;
		}
		
		this.mode = "";
		this.handleResize();
	}
	
	startCarousel()
	{
		if (this.carouselActive)
		{
			this.cInterval = setInterval(()=>{ 
				//console.log("count");
			
				this.countdown--;
			
				if (this.countdown <=0)
				{
					this.countdown=this.timeout;
					
					if (this.currentFont() < $(".fontlist-item").length-1)
						this.setFont(this.currentFont()+1);
					else
						this.setFont(0); 
				}
			
			}, 1000);
		}
	}
	
	stopCarousel()
	{
		clearInterval(this.cInterval);
		this.countdown=this.timeout;
	}
	
	disableCarousel()
	{
		this.carouselActive = false;
		this.stopCarousel();
	}
	
	handleResize()
	{
		//console.log("amount: "+($(".fontdisplay").width() - ($(".fontdisplay").height() - $(".fontdisplay .copy").height())));
		
		if ($(window).width() < 1025)
		{
			$("section.gap").css("height",window.innerHeight+"px");
			$("section.gap div.wdisplay").css("height",(window.innerHeight-300)+"px");
		}
		else
		{
			$("section.gap").attr("style","");
			$("section.gap div.wdisplay").attr("style","");
		}
	
		if ($(".fontdisplay").width() - ($(".fontdisplay").height() - $(".fontdisplay .copy").height()) > 0)
		{
			$("#vector").addClass("landscape");
			$("#vector").removeClass("portrait");
			//console.log("landscape");
		}
		else
		{
			$("#vector").addClass("portrait");
			$("#vector").removeClass("landscape");
			//console.log("portrait");
		}
		
		if ($("body").hasClass("stats"))
		{
		
			if ($(window).width() >= 1025 && this.mode!="desktop")
			{
				//console.log("------- desktop");
				this.mode="desktop";
			
			
				$(".fontlist-item").on("click", (e)=>{
					if (! $(e.currentTarget).hasClass("active"))
					{
						this.disableCarousel();
					
						this.setFont($(".fontlist-item").index($(e.currentTarget)[0]));
						e.preventDefault();
					}
				
				});
			
				this.startCarousel();
			
				$(document).mouseleave(()=>{
					//console.log("start");
					this.startCarousel();
				});
			
				$(document).mouseenter(()=>{
					//console.log("stop");
					this.stopCarousel();
				});
			
				$("section.stats").swipe("destroy");
				
				
			}
			else if ($(window).width() < 1025 && this.mode!="mobile")
			{
				//console.log("------- mobile");
				if (this.currentFont() == 0)
				{
					$($(".fontlist-item")[0]).addClass("active");
				}
		
				this.mode="mobile";
				$(".fontlist-item").off("click");
			
				$("section.stats").swipe({
						triggerOnTouchEnd: true,
						swipeStatus: (event, phase, direction, distance) => { this.fontsSwipeStatus(event, phase, direction, distance); },
						allowPageScroll: "vertical",
						threshold: this.swipeThreshold
					});
					
				
			  
				this.stopCarousel();
				$(document).off("mouseleave");
				$(document).off("mouseenter");
			}
		}
		else if ($("body").hasClass("gap"))
		{
			if ($(window).width() >= 1025 && this.mode!="desktop")
			{
				$("section.gap").swipe("destroy");
			}
			else if ($(window).width() < 1025 && this.mode!="mobile")
			{
				
				$("section.gap").swipe({
						triggerOnTouchEnd: true,
						swipeStatus: (event, phase, direction, distance) => { this.gapSwipeStatus(event, phase, direction, distance); },
						allowPageScroll: "vertical",
						threshold: this.swipeThreshold
					});
				
			}
			
			this.mode="";
			this.stopCarousel();
			$(document).off("mouseleave");
			$(document).off("mouseenter");
			$(".fontlist-item").off("click");
			
			$("section.stats").swipe("destroy");
		}
		else
		{
			this.mode="";
			this.stopCarousel();
			$(document).off("mouseleave");
			$(document).off("mouseenter");
			$(".fontlist-item").off("click");
			
			$("section.gap").swipe("destroy");
			$("section.stats").swipe("destroy");
		}
		
		this.handleScroll();
	}
	
	handleScroll ()
	{
		if (this.loadedW)
		{
			let scrollAmount = $(window).scrollTop() / ($("body").height()-$(window).height());
			let items = $("section.gap .timeline .timeline-item");
			let count = items.length-1;
		
			let index = Math.round(count*scrollAmount);
			
			this.showGapIndex(index);
		}
	}
	
	nextGap() {
		
		this.setGapScroll(this.gapIndex+1);
	}
	
	prevGap() {
		this.setGapScroll(this.gapIndex-1);
	}
	
	setGapScroll (index)
	{
		let newIndex = index;
		let items = $("section.gap .timeline .timeline-item");
		let count = items.length-1;
		
		if (newIndex > count)
			newIndex = 0;
		if (newIndex < 0)
			newIndex = count;
		
		
		let dest = (($("body").height()-$(window).height()) / count)*newIndex;
		
		$(window).scrollTop(dest);
	}
	
	showGapIndex (index)
	{
	
		this.gapIndex = index;
		let items = $("section.gap .timeline .timeline-item");
		let count = items.length-1;
		
		//console.log(index);
	
		let pc = parseFloat($($("section.gap .timeline .timeline-item")[index]).data("percentage"));
		
		let basePc = parseFloat($($("section.gap .timeline .timeline-item")[0]).data("percentage"));
		
		let animPer = 100 - ((pc/basePc)*100);
		//console.log("basePc "+basePc);
		//console.log("animPer "+animPer);
		
		this.setGap(animPer);
		
		
		if ($(window).width() >= 1025 && this.mode!="desktop")
		{
			$("#underlay").css("transform", "translate(0%, -50%) rotate("+((pc/100)*110)+"deg)");
		}
		else
		{
			$("#underlay").css("transform", "translate(0%, -50%) rotate("+(90-(pc/100)*120)+"deg)");
		}
		
		// 0 == 50
		// 100 = 45
		
		for (let i=0; i<=count; i++)
		{
			$(items[i]).removeClass("active-3");
			$(items[i]).removeClass("active-2");
			$(items[i]).removeClass("active-1");
			$(items[i]).removeClass("active");
		
			if (Math.abs(i-index) == 3)
				$(items[i]).addClass("active-3");
			else if (Math.abs(i-index) == 2)
				$(items[i]).addClass("active-2");
			else if (Math.abs(i-index) == 1)
				$(items[i]).addClass("active-1");
			else if (Math.abs(i-index) == 0)
			{
				$(items[i]).addClass("active");
				
				$("#gap-mobile-details").html($(items[i]).find(".content")[0].outerHTML);
			}
		}
		
		
		
		let space = $(".timeline .timeline-item").width();
		let left = $(window).width()/2 - space*index;
		
		//console.log("left "+left);
		
		$(".timeline > div").css("left", left+"px");
	}
	
	fontsSwipeStatus (event, phase, direction, distance)
	{
		/*
		console.log("You swiped " + direction );  
		if (direction == "left")
		{
		this.nextFont();
		}
		else if (direction == "right")
		{
		this.prevFont();
		}
		*/
		
		//If we are moving before swipe, and we are going L or R in X mode, or U or D in Y mode then drag.
		if (phase == "start") {
			this.fontOffset = 0;
		}
		else if (phase == "move" && (direction == "left" || direction == "right")) {
			
			var duration = 0;
			
			let targetFont = this.currentFont();
			
			//console.log("distance "+distance);
			//console.log("this.fontOffset "+this.fontOffset);
			
			let dist = distance - this.fontOffset;

			if (direction == "left") {
				//targetFont += (distance/this.swipeThreshold);
				
				if (Math.abs(dist) > this.swipeThreshold)
				{
				
					if (dist > 0)
						this.nextFont();
					else
						this.prevFont();
						
					this.fontOffset = distance - distance%this.swipeThreshold;
				}
				
				//scrollImages((IMG_WIDTH * currentImg) + distance, duration);
			} else if (direction == "right") {
				
				if (Math.abs(dist) > this.swipeThreshold)
				{
					if (dist < 0)
						this.nextFont();
					else
						this.prevFont();
						
					this.fontOffset = distance - distance%this.swipeThreshold;
				}
				
				//targetFont -= (distance/this.swipeThreshold);
				//scrollImages((IMG_WIDTH * currentImg) - distance, duration);
			}
			
			/* */

		} else if (phase == "cancel") {
			//console.log("snap back to current");
			this.setFont(this.currentFont());
			//scrollImages(IMG_WIDTH * currentImg, speed);
		} else if (phase == "end") {
			//this.setFont(Math.round(this.workingFont));
			
			/*
			if (direction == "right") {
				this.prevFont();
				//this.setFont(Math.round(this.workingFont));
			} else if (direction == "left") {
				this.nextFont();
			}
			*/
			/* */
		}
	}
	
	gapSwipeStatus (event, phase, direction, distance)
	{
		/*
		console.log("You swiped " + direction );  
		if (direction == "left")
		{
		this.nextFont();
		}
		else if (direction == "right")
		{
		this.prevFont();
		}
		*/
		
		//console.log("You swiped " + direction );  
		
		//If we are moving before swipe, and we are going L or R in X mode, or U or D in Y mode then drag.
		if (phase == "start") {
			this.gapOffset = 0;
			//console.log("start");
		}
		else if (phase == "move" && (direction == "left" || direction == "right")) {
			
			var duration = 0;
			
			let dist = distance - this.gapOffset;

			if (direction == "left") {
				//targetFont += (distance/this.swipeThreshold);
				
				if (Math.abs(dist) > this.swipeThreshold)
				{
				
					if (dist > 0)
						this.nextGap();
					else
						this.prevGap();
						
					this.gapOffset = distance - distance%this.swipeThreshold;
				}
				
				//scrollImages((IMG_WIDTH * currentImg) + distance, duration);
			} else if (direction == "right") {
				
				if (Math.abs(dist) > this.swipeThreshold)
				{
					if (dist < 0)
						this.nextGap();
					else
						this.prevGap();
						
					this.gapOffset = distance - distance%this.swipeThreshold;
				}
				
				//targetFont -= (distance/this.swipeThreshold);
				//scrollImages((IMG_WIDTH * currentImg) - distance, duration);
			}
			
			/* */

		} else if (phase == "cancel") {
			//console.log("snap back to current");
			//this.setFont(this.currentFont());
			//scrollImages(IMG_WIDTH * currentImg, speed);
		} else if (phase == "end") {
			//this.setFont(Math.round(this.workingFont));
			
			/*
			if (direction == "right") {
				this.prevFont();
				//this.setFont(Math.round(this.workingFont));
			} else if (direction == "left") {
				this.nextFont();
			}
			*/
			/* */
		}
	}
	
	//animation
	
	nextFont ()
	{
		this.disableCarousel();
		
		if (this.currentFont() < $(".fontlist-item").length-1)
			this.setFont(this.currentFont()+1);
		else
			this.setFont(0); 
	}
	
	prevFont ()
	{
		this.disableCarousel();
		
		if (this.currentFont() > 0)
			this.setFont(this.currentFont()-1);
		else
			this.setFont($(".fontlist-item").length-1);
	}
	
	setFont (count)
	{
		let fixedCount = Math.round(count);
		
		if (this.fixedCount > $(".fontlist-item").length-1)
			this.fixedCount = 0;
		if (this.fixedCount < 0)
			this.fixedCount = $(".fontlist-item").length-1;
	
		
		$(".fontlist-item").removeClass("active");
		$($(".fontlist-item")[fixedCount]).addClass("active");
		
		$(".pips .pip").removeClass("active");
		$($(".pips .pip")[fixedCount]).addClass("active");
		
		$("body").removeClass("pink");
		$("body").removeClass("orange");
		$("body").removeClass("cherry");
		
		switch (fixedCount%3)
		{
			case 0:
				$("body").addClass("pink");
				break;
			case 1:
				$("body").addClass("orange");
				break;
			case 2:
				$("body").addClass("cherry");
				break;
		}
		
		/*
		if (count == 0)
		{
			$(".copy-mobile").html(this.mobileCopy);
		}
		else
		{
			$(".copy-mobile").html("<p>"+$(".fontlist-item.active .details").html()+"</p>");
		}
		*/
		$(".copy-mobile").html("<p>"+$(".fontlist-item.active .details").html()+"</p>");
	
		this.setState($($(".fontlist-item")[count]).data("animid"));
	}
	
	currentFont ()
	{
		if ($(".fontlist-item.active").length == 0)
			return 0;
		else
			return $(".fontlist-item").index($(".fontlist-item.active")[0]);
	}
	

	setState(idState, immediate = false) {

		idState = parseInt(idState);
	
		var _targets = [];
		var _values = [];

		this.STATES[idState].map((s)=> {
			_targets.push('#'+this.LAYERS_DICT[s.layerId]);
			_values.push(s.endValue);
		});


	
		for (var i=0; i<_targets.length; i++ ) {
			if (immediate)
			{
				var a=anime({
					targets: _targets[i],
					d : _values[i],
					easing: 'easeOutQuad',
					duration : 1
				});
			}
			else
			{
				var a=anime({
					targets: _targets[i],
					d : _values[i],
					easing: 'easeOutQuad',
					duration : 400  
				});
			}
		}
	}

	/*
	setState(idState, immediate = false) {
	
		let stateA = parseInt(Math.floor(idState));
		let stateB = parseInt(Math.ceil(idState));
		
		let stateBAmount = idState-stateA;
		
		if (stateA < 0)
			stateA = $(".fontlist-item").length-1;
		if (stateB > $(".fontlist-item").length-1)
			stateB = 0;
	
		//$($(".fontlist-item")[stateA]).data("animid")

		idState = parseInt(idState);
	
		var _targets = [];
		var _values = [];

		this.STATES[stateA].map((s)=> {
			let bValue = null;
			
			_targets.push('#'+this.LAYERS_DICT[s.layerId]);
			
			_values.push(s.endValue);
		});
		
		this.STATES[stateB].map((s)=> {
			_targets.push('#'+this.LAYERS_DICT[s.layerId]);
			_values.push(s.endValue);
		});

		
		if (immediate)
		{
			for (var i=0; i<_targets.length; i++ ) {
		
				var a=anime({
					targets: _targets[i],
					d : _values[i],
					easing: 'easeOutQuad',
					duration : 1,        
			
				});
			}
		}
		else
		{
			for (var i=0; i<_targets.length; i++ ) {
		
				var a=anime({
					targets: _targets[i],
					d : _values[i],
					easing: 'easeOutQuad',
					duration : 400,        
			
				});
			}
		}
	}
	*/
	
	
	
	init_animation(json_obj) {
	
	
		var JSON_layers = jsonQ(json_obj['layers']['vectorLayer']);

	
		var paths = JSON_layers.find("type", function (){
			return this == 'path';
		}).parent().value();

		// create LAYERS_DICT (animations are based on layerId. SVG objects use a 'name' ID. This dict connects boths. Key => layerId, value : 'name' ID)
		paths.map((p)=> {        
			this.LAYERS_DICT[p.id] = p.name;
		});

		// save 'name' by GROUPS
		// 1. Get Group Names
		var JSON_groups = JSON_layers.find("type",  () => {
			return this == 'group';
		}).sibling('name').value();

		// 2. Get Children names per group
		for (var g of JSON_groups) 
		{
			var children = JSON_layers.find('name', () => {
				return this == g;
			}).sibling('children').value()[0];

			this.GROUPS[g] = [];

			for (var c in children) {
				this.GROUPS[g].push(children[c].name);
			}                
		}
	

		// -------------------------------------------------------------------------------------

		// and then get from timeline (endtime property / 100)
	
		var JSON_states = json_obj['timeline']['animation']['blocks'];
		JSON_states.map((a) => {
			var state = a.endTime/100;
			var layerId = a.layerId;
			var endValue = a.toValue;

		
			if (!this.STATES[state]) this.STATES[state] = [];

			this.STATES[state].push({
				layerId : layerId,
				endValue : endValue
			});

			// add state 0 (kind of hacky for now)
			if (state==1) {
				if (!this.STATES[0]) this.STATES[0] = [];
			
				this.STATES[0].push({
					layerId : layerId,
					endValue : a.fromValue
				});
			}
		});

		this.setFont (0);
	}
	
	loadJSON(url, callback) {   
		var xobj = new XMLHttpRequest();
		xobj.overrideMimeType("application/json");
		xobj.open('GET', url, true);
		xobj.onreadystatechange = () => {
		  if (xobj.readyState == 4 && xobj.status == "200") {
			callback(JSON.parse(xobj.responseText));
		  }
		};
		xobj.send(null);  
	}
	
	init_animation2(json_obj) {
	
	
		var JSON_layers = jsonQ(json_obj['layers']['vectorLayer']);

	
		var paths = JSON_layers.find("type", function(){
			return this == 'path';
		}).parent().value();

		// create LAYERS_DICT (animations are based on layerId. SVG objects use a 'name' ID. This dict connects boths. Key => layerId, value : 'name' ID)
		paths.map((p) => {        
			this.LAYERS_DICT_2[p.id] = p.name;
		});

		// save 'name' by GROUPS
		// 1. Get Group Names
		var JSON_groups = JSON_layers.find("type", function() {
			return this == 'group';
		}).sibling('name').value();

		// 2. Get Children names per group
		for (var g of JSON_groups) 
		{
			var children = JSON_layers.find('name', function() {
				return this == g;
			}).sibling('children').value()[0];

			this.GROUPS_2[g] = [];

			for (var c in children) {
				this.GROUPS_2[g].push(children[c].name);
			}                
		}
	

		// -------------------------------------------------------------------------------------

		// and then get from timeline (endtime property / 100)
	
		var JSON_states = json_obj['timeline']['animation']['blocks'];
		JSON_states.map((a) => {
			var state = a.endTime/100;
			var layerId = a.layerId;
			var endValue = a.toValue;

		
			if (!this.STATES_2[state]) this.STATES_2[state] = [];

			this.STATES_2[state].push({
				layerId : layerId,
				endValue : endValue
			});

			// add state 0 (kind of hacky for now)
			if (state==1) {
				if (!this.STATES_2[0]) this.STATES_2[0] = [];
			
				this.STATES_2[0].push({
					layerId : layerId,
					endValue : a.fromValue
				});
			}
		});

		var _targets = [];
		var _values = [];

		var idState = 1;

		this.STATES_2[idState].map((s) => {
			_targets.push('#'+this.LAYERS_DICT_2[s.layerId]);
			_values.push(s.endValue);
		});

		for (var i=0; i<_targets.length; i++ ) {
		
			this.ANIMATIONS_2.push(anime({
				targets: _targets[i],
				d : _values[i],
				easing: 'easeOutQuad',
				duration : 400,        
				autoplay: false,            
			}));
		}

		//console.log(this.ANIMATIONS_2);
		
		
		this.loadedW = true;
		this.handleResize();
	}
	
	
	setGap(pc) {
		if (this.pc != pc)
		{

			var pcDiff = (this.PC_COUNTER.pc - pc)/ 100;
			var time = 500;//Math.abs(pcDiff)*this.ANIMATIONS_2[0].duration;
			
			if (this.a != undefined)
				this.a.pause();
	
			this.a = anime({
				targets: this.PC_COUNTER,
				pc : pc,
				duration : time,
				easing: 'linear',
				update : (anim) => {
					var _t = this.PC_COUNTER.pc/100 * this.ANIMATIONS_2[0].duration;            
					for (var i=0; i<this.ANIMATIONS_2.length; i++) {
						this.ANIMATIONS_2[i].seek(_t);
					}            
				},        
			}); 
		
			//console.log("setGap "+pc);
			this.pc = pc;
		}
	}

}
