import '../styles/index.scss';
import $ from 'jquery';
import App from './app';
import jsonQ from 'jsonq';
import anime from 'animejs';

window.$ = $;
window.jsonQ = jsonQ;
window.anime = anime;

const app = new App();
window.app = app;
